import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { makeRequest } from "../axios";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [loggedInUser, setLoggedInUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const [authToken, setAuthToken] = useState(
    JSON.parse(localStorage.getItem("authToken")) || null
  );

  // Logout
  const logout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/auth/logout/${loggedInUser?.userId}`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setLoggedInUser(null);
      setAuthToken(null);
    } catch (error) {
      console.log(error);
    }
  };

  // login
  const login = async (inputs) => {
    const res = await axios.post(
      `${process.env.REACT_APP_URL}/auth/login`,
      inputs,
      { withCredentials: true }
    );

    setLoggedInUser(res.data.userData);
    setAuthToken(res.data.jwtToken);
  };

  //console.log({ userData: loggedInUser, authToken: authToken });
  // Set user data in localstorage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(loggedInUser));
  }, [loggedInUser]);

  // Set auth token in localstorage
  useEffect(() => {
    localStorage.setItem("authToken", JSON.stringify(authToken));
  }, [authToken]);

  //Get user details
  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (!loggedInUser || !loggedInUser.userId) {
        setUserDetails([]);
        return null; // Return early to avoid making unnecessary requests
      }

      try {
        const res = await makeRequest.get(`/users/loggedInUser/`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setUserDetails(res.data[0]);
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    };
    fetchUserDetails();
  }, [authToken, loggedInUser]);

  // Restrict action after password or email update
  // const [isRestricted, setIsRestricted] = useState(false);

  // useEffect(() => {
  //   const checkRestriction = async () => {
  //     try {
  //       const response = await makeRequest.get("/check-restriction", {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       });
  //       setIsRestricted(!response.data.canProceed);
  //     } catch (error) {
  //       // console.error("Error checking restriction");
  //       // console.error("Error checking restriction:", error);
  //       // Handle error here (e.g., display a message to the user)
  //     }
  //   };

  //   if (loggedInUser) {
  //     checkRestriction();
  //   }
  // }, [authToken, loggedInUser]);

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        userDetails,
        authToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
